import { useCallback, useEffect, useState } from 'react';

import { ProductBadgesList } from '@/core/ui/components/ProductBadgesList/ProductBadgesList';
import { ProductImage } from '@/core/ui/components/ProductImage/ProductImage';
import { ProductPageLink } from '@/core/ui/components/ProductPageLink/ProductPageLink';
import { cn } from '@/core/ui/utils';
import { Product } from '@/modules/product/queries/types';
import { useSetTimeout } from '@lib/theme/hooks/useSetTimeout';

import { FoldableProductCounter } from './FoldableProductCounter';

export const ProductImageWithBadges = ({
  attributes,
  productName,
  productSlug,
  imageUrl,
  availableQuantity,
  isPublished,
  sku,
  isNotClickable,
  isPromoted,
}: {
  attributes: Product['attributes'];
  productName: string;
  productSlug: string;
  imageUrl: string;
  availableQuantity: number;
  isPublished: boolean;
  sku: string;
  isNotClickable?: boolean;
  isPromoted: boolean;
}) => {
  const [isCounterFolded, setIsCounterFolded] = useState(true);
  const [isMouseOverCounter, setIsMouseOverCounter] = useState(false);

  // Without useCallback here we trigger useEffect inside useSetTimeout on every render
  const foldCounter = useCallback(() => {
    if (isMouseOverCounter) return;
    return setIsCounterFolded(true);
  }, [isMouseOverCounter]);

  const { resetTimer } = useSetTimeout({
    onTimeout: foldCounter,
    startCountdown: !isCounterFolded,
    timeMs: autoFoldCounterTimeMs,
  });

  useEffect(() => {
    if (isMouseOverCounter) return;

    // reset the timer after mouse leaves the counter
    // otherwise, it will never close
    return resetTimer();
  }, [isMouseOverCounter, resetTimer]);

  const unFoldCounter = () => {
    setIsCounterFolded(false);
    resetTimer();
  };

  return (
    <div className={cn('relative')}>
      <div
        className={cn('absolute', 'top-2', 'right-2', 'z-10', 'xsm:right-4')}
      >
        <FoldableProductCounter
          sku={sku}
          availableQuantity={availableQuantity}
          isPublished={isPublished}
          size="md"
          iconSize="sm"
          isFolded={isCounterFolded}
          onClick={unFoldCounter}
          onMouseOverChange={setIsMouseOverCounter}
        />
      </div>
      <div
        className={cn(
          'absolute',
          'bottom-0',
          'left-2',
          'xsm:left-4',
          'sm:top-4',
          'sm:bottom-auto',
          'z-10',
          isPromoted && 'sm:top-9'
        )}
      >
        <ProductBadgesList
          tags={attributes.tags}
          direction="column"
          maxNumberOfBadgesOnDesktop={3}
          maxNumberOfBadgesOnMobile={3}
          showOnlyIcon
          unfoldOnHover={isCounterFolded}
          color={isPromoted ? 'promoted' : 'primary'}
        />
      </div>
      <div
        className={cn(
          'justify-center',
          'flex',
          'xsm:mx-7',
          'sm:mx-[38px]',
          'lmd:mx-14',
          'mt-[38px]',
          'xsm:mt-12'
        )}
      >
        <ProductPageLink
          productSlug={productSlug}
          className={cn('min-w-[112px]', 'w-28', 'p-2')}
          isDisabled={!isPublished || isNotClickable}
        >
          <ProductImage alt={productName} url={imageUrl} />
        </ProductPageLink>
      </div>
    </div>
  );
};

const autoFoldCounterTimeMs = 3000;
