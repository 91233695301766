import { PRODUCT_TILE_HEIGHT_CLASS } from '@/core/constants/productTile';
import { cn } from '@/core/ui/utils';

import { Skeleton } from '../../Skeleton/Skeleton';

export const ProductTileBorderedSkeleton = () => (
  <li
    aria-hidden
    className={cn(
      PRODUCT_TILE_HEIGHT_CLASS,
      'relative',
      'rounded-xl',
      'border',
      'border-gray-200'
    )}
  >
    <Skeleton className="h-full" />
  </li>
);
