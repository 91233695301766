import { Trans, useTranslation } from 'next-i18next';
import { ReactNode, useMemo } from 'react';

import { Badge } from '@/core/ui/components/Badge/Badge';
import { FormattedPrice } from '@/core/ui/components/FormattedPrice/FormattedPrice';
import { cn } from '@/core/ui/utils';
import { Product } from '@/modules/product/queries/types';
import { checkPriceDifference } from '@/modules/product/services/checkPriceDifference';

export const Omnibus = ({
  price,
  isPromoted,
  children,
}: {
  price: Product['price'];
  isPromoted: boolean;
  children: ReactNode;
}) => {
  const { t } = useTranslation('product');

  const pricePercentageDifference = useMemo(
    () => checkPriceDifference(price.discounted?.discount?.value),
    [price.discounted?.discount?.value]
  );

  return (
    <div
      className={cn('md:gap-x-2', 'md:items-center', 'md:mt-1', 'space-y-150')}
    >
      {price?.discounted && (
        <p
          className={cn(
            'text-xs',
            isPromoted ? 'text-text-subtle' : 'text-gray-500'
          )}
        >
          {t('lowest price')}
        </p>
      )}
      <div className="flex gap-150 md:-order-1">
        {pricePercentageDifference.discountType ? (
          <Badge variant={isPromoted ? 'promoted' : 'default'}>
            {pricePercentageDifference.discountType === 'PERCENTAGE' ? (
              <Trans
                ns="product"
                i18nKey="-{{value}}%"
                values={{
                  value: Math.ceil(pricePercentageDifference.difference),
                }}
              />
            ) : (
              <FormattedPrice
                hideDecimals
                centAmount={pricePercentageDifference.difference}
                currencyCode={pricePercentageDifference.currencyCode}
              />
            )}
          </Badge>
        ) : null}
        {children}
      </div>
    </div>
  );
};
