import { memo } from 'react';
import { useInView } from 'react-intersection-observer';

import { FC } from '@/types/common';

import { ProductTileBorderedSkeleton } from './components/ProductTileBorderedSkeleton';
import {
  ProductTileInner,
  ProductTileInnerProps,
} from './components/ProductTileInner';

export const ProductTile: FC<ProductTileInnerProps> = memo(
  ({ product, ...restProps }) => {
    const [setTileRef, isTileInViewport] = useInView({
      triggerOnce: true,
    });
    return (
      <div ref={setTileRef} data-testid="wrapper" data-product-id={product.id}>
        {isTileInViewport ? (
          <ProductTileInner product={product} {...restProps} />
        ) : (
          <ProductTileBorderedSkeleton />
        )}
      </div>
    );
  }
);
