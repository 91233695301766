import { DecreaseQuantityButton } from '@/core/ui/components/DecreaseQuantityButton/DecreaseQuantityButton';
import { IncreaseQuantityButton } from '@/core/ui/components/IncreaseQuantityButton/IncreaseQuantityButton';
import { cn } from '@/core/ui/utils';
import { useProductLineItem } from '@/modules/cart/hooks/useProductLineItem';
import { FC } from '@/types/common';
import { Button } from '@lib/theme/components/Button';
import { ButtonSizeVariant } from '@lib/theme/config/createButtonClasses';

export const FoldableProductCounter: FC<FoldableProductCounterProps> = ({
  sku,
  availableQuantity,
  isPublished,
  size,
  iconSize,
  isFolded,
  onClick,
  onMouseOverChange,
  attributionToken,
}) => {
  const productLineItem = useProductLineItem(sku);
  const quantityInCurrentCart = productLineItem?.quantity ?? 0;
  const isProductAvailable =
    availableQuantity > quantityInCurrentCart && isPublished;
  const buttonSize = buttonSizes[size];

  const onMouseOver = () => onMouseOverChange(true);
  const onMouseLeave = () => onMouseOverChange(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  if (quantityInCurrentCart === 0) {
    return (
      <IncreaseQuantityButton
        size={buttonSize}
        sku={sku}
        isProductAvailable={isProductAvailable}
        iconSize={iconSize}
        onClick={onClick}
        attributionToken={attributionToken}
      />
    );
  }

  if (quantityInCurrentCart > 0 && isFolded) {
    return (
      <Button
        size={buttonSize}
        color="tertiary/counterNarrow/active"
        round="full"
        width="w-auto"
        padding="none"
        onClick={onClick}
        testId="product-counter"
      >
        <span className="text-lg">{quantityInCurrentCart}</span>
      </Button>
    );
  }

  return (
    <div
      onClick={onClick}
      className={cn(
        'flex',
        'items-center',
        'bg-primary-400',
        'rounded-full',
        'border-2',
        'border-primary-400'
      )}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onFocus={() => {
        onMouseOver();
      }}
    >
      <DecreaseQuantityButton
        sku={sku}
        size={buttonSize}
        iconSize={iconSize}
        onClick={onClick}
        attributionToken={attributionToken}
      />
      <span
        className={cn('text-white', {
          [cn('mx-3', 'text-lg')]: size === 'md',
          [cn('mx-2', 'text-base')]: size === 'sm' || size === 'xs',
        })}
        data-testid="ProductCounterNarrow__quantity-in-active-cart"
      >
        {quantityInCurrentCart}
      </span>
      <IncreaseQuantityButton
        size={buttonSize}
        sku={sku}
        isProductAvailable={isProductAvailable}
        iconSize={iconSize}
        onClick={onClick}
        attributionToken={attributionToken}
      />
    </div>
  );
};

const buttonSizes: Record<ButtonSizeVariant, ButtonSizeVariant> = {
  xs: 'xs',
  lg: 'lg',
  base: 'base',
  md: 'md',
  '2md': '2md',
  sm: 'sm',
};

type FoldableProductCounterProps = {
  sku: string;
  availableQuantity: number;
  isPublished: boolean;
  size: ButtonSizeVariant;
  iconSize: ButtonSizeVariant;
  isFolded: boolean;
  onClick: () => void;
  onMouseOverChange: (value: boolean) => void;
  attributionToken?: string;
};
