import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

import { useAddLineItemToCurrentCart } from '@/modules/cart/hooks/useAddLineItemToCurrentCart';
import { FC } from '@/types/common';
import { ButtonProps } from '@lib/theme/components/Button';
import { IconOnlyButton } from '@lib/theme/components/IconOnlyButton';

export const IncreaseQuantityButton: FC<Props> = ({
  sku,
  isProductAvailable,
  size,
  iconSize,
  color,
  onClick,
  attributionToken,
  className,
}) => {
  const { t } = useTranslation('product');
  const [increaseProductAmount, loading] = useAddLineItemToCurrentCart();
  const onButtonClick = useCallback(async () => {
    await increaseProductAmount([{ sku }], attributionToken);
    if (onClick) {
      onClick();
    }
  }, [increaseProductAmount, attributionToken, onClick, sku]);

  return (
    <IconOnlyButton
      size={size}
      round="full"
      onClick={onButtonClick}
      icon="PlusIcon"
      color={color ?? 'tertiary/counterNarrow'}
      isLoading={loading}
      isDisabled={!isProductAvailable}
      title={isProductAvailable ? t('Add product to cart') : t('Out of stock')}
      loadingIndicatorTitle={t('Adding product to cart...')}
      iconSize={iconSize}
      testId={isProductAvailable ? 'add-to-cart-button' : 'out-of-stock-button'}
      className={className}
    />
  );
};

type Props = {
  sku: string;
  isProductAvailable: boolean;
  size: ButtonProps['size'];
  iconSize?: ButtonProps['size'];
  color?: ButtonProps['color'];
  onClick?: () => void;
  attributionToken?: string;
  className?: string;
};
