import { useTranslation } from 'next-i18next';

export const ProductTilePromotedBadge = () => {
  const { t } = useTranslation('product');
  return (
    <div className="absolute -left-px -top-px flex h-5 w-[59px] items-center justify-center rounded-br-3xl rounded-tl-xl bg-surface-top text-label-lg uppercase text-white">
      {t('Top')}
    </div>
  );
};
