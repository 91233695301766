import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';

export const OutOfStockBadge = () => {
  const { t } = useTranslation('product');

  return (
    <div
      className={cn(
        'px-4',
        'py-1',
        'rounded-full',
        'text-xs',
        'box-content',
        'flex',
        'justify-center',
        'items-center',
        'bg-gray-500',
        'text-white'
      )}
    >
      {t('Out of stock')}
    </div>
  );
};
