import { cn } from '@/core/ui/utils';

import { ProductPageLink } from '../../ProductPageLink/ProductPageLink';

export const ProductName = ({
  productSlug,
  productName,
  isPublished,
  isNotClickable,
}: {
  productSlug: string;
  productName: string;
  isPublished: boolean;
  isNotClickable?: boolean;
}) => (
  <ProductPageLink
    productSlug={productSlug}
    className={cn('m-2', 'xsm:mx-4', 'mb-1', 'block', 'xsm:mt-1', 'md:mb-2')}
    isDisabled={!isPublished || isNotClickable}
  >
    <p
      title={productName}
      className={cn(
        'text-sm',
        'font-medium',
        'line-clamp-2',
        'lmd:text-base',
        'lmd:leading-5'
      )}
    >
      {productName}
    </p>
  </ProductPageLink>
);
