import { memo, ReactNode } from 'react';

import { PRODUCT_TILE_HEIGHT_CLASS } from '@/core/constants/productTile';
import { FormattedNetContain } from '@/core/ui/components/FormattedNetContain/FormattedNetContain';
import { cn } from '@/core/ui/utils';
import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';
import { Product } from '@/modules/product/queries/types';

import { BundleBadge } from '../../BundleBadge/BundleBadge';

import { AlcoholProductTileOverlay } from './AlcoholProductTileOverlay';
import { Omnibus } from './Omnibus';
import { OutOfStockBadge } from './OutOfStockBadge';
import { ProductImageWithBadges } from './ProductImageWithBadges';
import { ProductName } from './ProductName';
import { ProductTilePrice } from './ProductTilePrice';
import { ProductTilePromotedBadge } from './ProductTilePromotedBadge';

export const ProductTileInner = memo(
  ({
    product: {
      imagesUrls: [imageUrl = ''],
      name: productName,
      slug: productSlug,
      price,
      attributes,
      availableQuantity,
      isPublished,
      sku,
      productType,
      ...product
    },
    additionalBottomContent,
    isNotClickable,
    showPromoted,
  }: ProductTileInnerProps) => {
    const authRole = useSession('authRole');

    const isOnStock = availableQuantity > 0 && isPublished;
    const shouldShowCover =
      authRole !== AUTH_ROLE.AUTHENTICATED && attributes?.contains_alcohol;
    const hasMaxQuantity = Boolean(price?.multibuy?.maxQuantity);
    const isPromoted = Boolean(
      showPromoted &&
        'isPromotedProduct' in product &&
        product?.isPromotedProduct
    );
    const isBundle = productType === 'complex-bundle';

    return (
      <li
        data-testid={`product-tile-${productSlug}`}
        className={cn(
          'relative',
          'rounded-xl',
          !shouldShowCover && ['border', 'border-gray-200'],
          !isOnStock && 'grayscale',
          PRODUCT_TILE_HEIGHT_CLASS,
          'box-content',
          isPromoted &&
            'border-decorative-surface-top bg-decorative-surface-top'
        )}
      >
        {isPromoted && <ProductTilePromotedBadge />}
        {shouldShowCover && <AlcoholProductTileOverlay />}
        <div
          className={cn(
            shouldShowCover && 'blur',
            'h-full',
            'relative',
            'flex',
            'flex-col'
          )}
        >
          <ProductImageWithBadges
            attributes={attributes}
            productName={productName}
            productSlug={productSlug}
            imageUrl={imageUrl}
            availableQuantity={availableQuantity}
            isPublished={isPublished}
            sku={sku}
            isNotClickable={isNotClickable}
            isPromoted={isPromoted}
          />
          <ProductName
            productName={productName}
            productSlug={productSlug}
            isPublished={isPublished}
            isNotClickable={isNotClickable}
          />
          <p
            className={cn(
              'text-xs',
              'truncate',
              'mx-2',
              'xsm:mx-4',
              isPromoted ? 'text-text-subtle' : 'text-gray-500'
            )}
          >
            <FormattedNetContain
              netContain={attributes.net_contain}
              containUnit={attributes.contain_unit}
            />
          </p>
          <div
            className={cn(
              'mx-2',
              'xsm:mx-4',
              'grow',
              'mb-5',
              'flex',
              'flex-col',
              'justify-end'
            )}
          >
            <div
              className={cn('flex', !isOnStock && cn('flex-col', 'mt-auto'))}
            >
              {isOnStock ? (
                <ProductTilePrice price={price} isPromoted={isPromoted} />
              ) : (
                <div className={cn('mt-1')}>
                  <OutOfStockBadge />
                </div>
              )}
            </div>
            {isOnStock && (
              <div className="flex gap-150">
                {price?.discounted ? (
                  <Omnibus price={price} isPromoted={isPromoted}>
                    {isBundle && <BundleBadge />}
                  </Omnibus>
                ) : (
                  isBundle && <BundleBadge />
                )}
              </div>
            )}
            {isOnStock &&
              price?.multibuy?.description &&
              !price?.discounted && (
                <div
                  className={cn(
                    'text-caption-sm',
                    'rounded-3xl',
                    'text-white',
                    'py-1',
                    'px-2',
                    'self-start',
                    'mt-2',
                    'lg:font-medium',
                    'lg:text-body-sm',
                    'lg:px-2.5',
                    hasMaxQuantity
                      ? 'bg-decorative-surface-three'
                      : 'bg-badge-multibuy'
                  )}
                >
                  {price.multibuy.description}
                </div>
              )}

            {additionalBottomContent}
          </div>
        </div>
      </li>
    );
  }
);

export type ProductTileInnerProps = {
  product: Product;
  additionalBottomContent?: ReactNode;
  isNotClickable?: boolean;
  showPromoted?: boolean;
};
