import { useTranslation } from 'next-i18next';

import { useRemoveLineItemsFromCurrentCart } from '@/modules/cart/hooks/useRemoveLineItemsFromCurrentCart';
import { FC } from '@/types/common';
import { ButtonProps } from '@lib/theme/components/Button';
import { IconOnlyButton } from '@lib/theme/components/IconOnlyButton';

export const DecreaseQuantityButton: FC<Props> = ({
  sku,
  size,
  iconSize,
  onClick,
  attributionToken,
  className,
}) => {
  const { t } = useTranslation('product');
  const [decreaseProductAmount, mutationResult] =
    useRemoveLineItemsFromCurrentCart();

  return (
    <IconOnlyButton
      size={size}
      round="full"
      onClick={async () => {
        await decreaseProductAmount({
          removeLineItems: [{ sku, quantity: 1 }],
          attributionToken,
        });
        onClick?.();
      }}
      icon="MinusIcon"
      color="tertiary/counterNarrow"
      isLoading={mutationResult.loading}
      isDisabled={false}
      title={t('Remove product from cart')}
      loadingIndicatorTitle={t('Removing product from cart...')}
      iconSize={iconSize}
      className={className}
    />
  );
};

type Props = {
  sku: string;
  size?: ButtonProps['size'];
  iconSize?: ButtonProps['size'];
  onClick?: () => void;
  attributionToken?: string;
  className?: string;
};
