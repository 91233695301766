import { cn } from '@/core/ui/utils';
import { forwardRef, MouseEvent, ReactNode } from 'react';
import { useDebounce } from 'use-debounce';

import { LOADING_DEBOUNCE_MS } from '../config/consts';
import {
  ButtonColorVariant,
  ButtonPaddingVariant,
  ButtonRoundVariant,
  ButtonSizeVariant,
  ButtonWidthVariant,
  createButtonClasses,
  DEFAULT_BUTTON_SIZE,
} from '../config/createButtonClasses';

import { SpinnerIcon } from './SpinnerIcon';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color,
      isLoading,
      isDisabled,
      round,
      size = DEFAULT_BUTTON_SIZE,
      type = 'button',
      onClick,
      loadingIndicatorTitle,
      width,
      children,
      padding,
      testId,
      className,
    },
    ref
  ) => {
    // Debounce loader rendering, in case the response is quick
    const [debouncedIsLoading] = useDebounce(isLoading, LOADING_DEBOUNCE_MS);
    return (
      <button
        ref={ref}
        // Disabled due to is a property with correct type
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={createButtonClasses({
          size,
          round,
          isLoading,
          color,
          width,
          padding,
          className,
        })}
        disabled={isDisabled || debouncedIsLoading}
        data-testid={testId}
        // IMPORTANT: remove onClick here when isLoading to not allow for
        // overlapping requests during debounce time
        onClick={isLoading ? undefined : onClick}
      >
        <span
          className={cn('block', 'm-auto', { invisible: debouncedIsLoading })}
        >
          {children}
        </span>
        {debouncedIsLoading && (
          <span
            className={cn(
              'absolute',
              'top-1/2',
              'left-1/2',
              '-translate-x-1/2',
              '-translate-y-1/2'
            )}
          >
            <SpinnerIcon
              title={loadingIndicatorTitle ?? ''}
              className={cn('w-6', 'h-6')}
            />
          </span>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export type ButtonProps = {
  className?: string;
  round?: ButtonRoundVariant;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
  isDisabled?: boolean;
  color: ButtonColorVariant;
  size?: ButtonSizeVariant;
  width?: ButtonWidthVariant;
  padding?: ButtonPaddingVariant;
  children?: ReactNode;
  testId?: string;
} & (
  | {
      isLoading?: undefined;
      loadingIndicatorTitle?: undefined;
    }
  | {
      isLoading: boolean;
      loadingIndicatorTitle: string;
    }
);
