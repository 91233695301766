import { cn } from '@/core/ui/utils';
import { forwardRef } from 'react';

import { buttonIconComponents, buttonIconSizes } from '../config/buttonIcons';
import {
  ButtonSizeVariant,
  DEFAULT_BUTTON_SIZE,
} from '../config/createButtonClasses';

import { AccessibleSvg } from './AccessibleSvg';
import { Button, ButtonProps } from './Button';

export const IconOnlyButton = forwardRef<
  HTMLButtonElement,
  IconOnlyButtonProps
>(
  (
    {
      icon,
      label,
      title,
      size = DEFAULT_BUTTON_SIZE,
      padding = 'none',
      whitespace = 'whitespace-normal',
      iconSize,
      ...props
    },
    ref
  ) => {
    const IconComponent = buttonIconComponents[icon];
    const iconClasses = buttonIconSizes[iconSize || size];

    return (
      <Button ref={ref} size={size} padding={padding} {...props}>
        <div className={cn('flex', 'flex-col', 'items-center')}>
          <AccessibleSvg
            SvgComponent={IconComponent}
            className={cn(iconClasses)}
            title={title}
          />
          {label && (
            <span
              className={cn(
                'hidden',
                'xl:inline',
                'text-primary-900',
                'text-sm',
                whitespace
              )}
            >
              {label}
            </span>
          )}
        </div>
      </Button>
    );
  }
);

IconOnlyButton.displayName = 'IconOnlyButton';

type IconOnlyButtonProps = ButtonProps & {
  icon: keyof typeof buttonIconComponents;
  label?: string;
  title: string;
  whitespace?: 'whitespace-nowrap';
  iconSize?: ButtonSizeVariant;
  className?: string;
};
