import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook equivalent of `setTimeout` to use in a React component.
 *
 * Make sure you wrap `onTimeout` in `useCallback`, otherwise
 * you cause `useEffect` to re-run on every render.
 */
export const useSetTimeout = ({
  onTimeout,
  startCountdown,
  timeMs,
}: {
  onTimeout: () => void;
  startCountdown?: boolean;
  timeMs: number;
}) => {
  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const clearTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  // useCallback is important here since someone may use this function inside useEffect
  const resetTimer = useCallback(() => {
    clearTimer();
    timer.current = setTimeout(() => {
      onTimeout();
    }, timeMs);
  }, [onTimeout, timeMs]);

  useEffect(() => {
    if (startCountdown === undefined || startCountdown) {
      timer.current = setTimeout(() => {
        onTimeout();
      }, timeMs);
    }
    return () => clearTimer();
  }, [onTimeout, startCountdown, timeMs]);

  return {
    resetTimer,
  };
};
