import Link from 'next/link';
import { useRouter } from 'next/router';

import { createProductCanonicalPath } from '@/core/services/createProductCanonicalPath';
import { FCC } from '@/types/common';

export const ProductPageLink: FCC<ProductPageLinkProps> = ({
  children,
  productSlug,
  className,
  isDisabled,
}) => {
  const { asPath, query } = useRouter();
  const attributionToken = query?.attributionToken
    ? String(query.attributionToken)
    : undefined;
  const href = createProductCanonicalPath(productSlug, attributionToken);

  if (isDisabled) {
    return <span className={className}>{children}</span>;
  }

  return (
    <Link
      href={href}
      shallow
      // We handle scroll to the top in the ProductDetails component, so that
      // we scroll once the product is loaded. This way we can avoid a short
      // blink of the previous product, when users are navigating to another
      // when they are on the product page already.

      // However, if the user clicks on the same product again, we still would
      // like to handle scroll here, because we never make the network request,
      // so the ProductDetails component does not know it has to scroll to
      // the top.
      scroll={asPath === href}
      passHref
      className={className}
    >
      {children}
    </Link>
  );
};

type ProductPageLinkProps = {
  productSlug: string;
  className?: string;
  isDisabled?: boolean;
};
