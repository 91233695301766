import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { Button } from '@lib/theme/components/Button';

export const AlcoholProductTileOverlay = () => {
  const { t } = useTranslation('product');
  const { push, asPath } = useRouter();

  const onOverlayClick = async () => {
    await push({
      pathname: '/sign-in',
      query: { afterAuthorizationDestination: asPath },
    });
  };

  return (
    <div
      className={cn(
        'absolute',
        'top-0',
        'left-0',
        'h-full',
        'w-full',
        'bg-primary-050',
        'z-20',
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'rounded-lg'
      )}
    >
      <p className={cn('text-sm', 'text-primary-900', 'text-center', 'pb-6')}>
        {t('Alcoholic beverage offer is available for signed in users.')}
      </p>
      <Button color="primary/primary" onClick={onOverlayClick}>
        {t('Sign in')}
      </Button>
    </div>
  );
};
